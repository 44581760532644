import React                          from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import Mailchimp                      from 'react-mailchimp-form'
import { injectIntl }                 from 'react-intl';

import { ContactContext } from '../../../providers';
import Spacer             from '../Spacer/Spacer';

const MCNewsletter = (props) => (
  <StaticQuery
    query={graphql`
          query NewsletterQuery {
            directusNewsletter {
             ...Newsletter
            }
          }
        `}
    render={(data) => {
      const newsletter = data.directusNewsletter;
      const intl = props.intl.formatMessage;
      return (
        <>
          <Mailchimp
            action={newsletter.endpoint}
            fields={[
              {
                name: 'EMAIL',
                placeholder: intl({ id: 'components.mcNewsletter.emailPlaceholder' }),
                type: 'email',
                required: true
              }
            ]}
            messages={
              {
                sending: 'Sendet...',
                success: 'Vielen dank fürs Abonnieren!',
                error: 'Ein unerwarteter Serverfehler ist aufgetreten',
                empty: 'Email Adresse fehlt',
                duplicate: 'Zu viele Anmeldeversuche mit dieser Email Adresse',
                button: 'Bestätigen'
              }
            }
          />
          <Spacer/>
          <div style={{ textAlign: 'justify' }}>
            <h3 style={{ textAlign: 'center' }}>Nach dem Bestätigen erhalten Sie von uns eine Email mit weiteren
              Informationen.</h3>
            <ContactContext.Consumer>
              {context => {
                return (
                  <>
                    <p>
                      {`Ich bin damit einverstanden, dass '${context.company_name}' meine Daten zu Werbezwecken verwendet und mir künftig
                    per E-Mail Informationen rund um die Produkte, Dienstleistungen, Aktionen u.a.m.
                    für ${newsletter.reason} zukommen lässt. Meine Daten werden ausschließlich zu diesem Zweck genutzt.
                    Insbesondere erfolgt keine Weitergabe an unberechtigte Dritte. Meine Einwilligung ist freiwillig und kann
                    jederzeit schriftlich bei '${context.company_name} - ${context.addresses[0].street} ${context.addresses[0].number} - ${context.addresses[0].city}' oder per E-Mail an
                    ${newsletter.mail}  widerrufen werden. Es gilt die`} <Link
                      to='/datenschutz'>Datenschutzerklärung</Link> {`von ${context.company_name}, die
                    auch weitere Informationen über Möglichkeiten zur Berichtigung, Löschung und Sperrung meiner Daten beinhaltet.`}
                    </p>
                  </>
                );
              }}
            </ContactContext.Consumer>
          </div>
        </>
      )
    }}/>
);

export default injectIntl(MCNewsletter);